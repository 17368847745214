import { StockStatus, type StockStatusType } from "@xxl/product-search-api";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../translations";
import {
  StoreStockStatus,
  StoreStockStatusIndicator,
} from "./StoreStockStyles";

type StoreStockLevelProps = {
  stockLevel: StockStatusType;
};

const getStockStatusTranslation = (
  stockLevel: StockStatusType
): TranslationKey => {
  switch (stockLevel) {
    case StockStatus.LOWSTOCK:
      return "product.details.storestock.low";
    case StockStatus.OUTOFSTOCK:
      return "product.details.storestock.out";
    default:
      return "product.details.storestock.in.stock";
  }
};

const StoreStockLevelElement = ({ stockLevel }: StoreStockLevelProps) => {
  const { t } = useTranslations();

  return (
    <StoreStockStatus>
      <StoreStockStatusIndicator stockStatus={stockLevel} />
      {t(getStockStatusTranslation(stockLevel))}
    </StoreStockStatus>
  );
};
export { StoreStockLevelElement };
