import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { StockStatus, type StockStatusType } from "@xxl/product-search-api";
import { color } from "@xxl/theme";
import { interactionFeedback, typographyToCss } from "../../styles/helpers";
import { colorsAsCssVariable } from "../../styles/theme/colors";
import spaces from "../../styles/theme/spaces";
import { xxlTheme } from "../../styles/xxl-theme";
import { assertNever } from "../../utils/xxl-assert-never";
import text from "../Product/DeliveryInfo/DeliveryInfo.typography";

const { colors: mainColors, typography } = xxlTheme;

interface StoreStockClickableListItemProps {
  isActive: boolean;
  isDisabled: boolean;
}

const getStockIndicatorColor = (
  stockStatus: StockStatusType | null
): string => {
  switch (stockStatus) {
    case StockStatus.LOWSTOCK:
      return colorsAsCssVariable.xxlOrange;
    case StockStatus.OUTOFSTOCK:
      return colorsAsCssVariable.xxlRed;
    case StockStatus.INSTOCK:
      return colorsAsCssVariable.xxlWebGreen;
    case null:
      return colorsAsCssVariable.xxlGrey;
    default:
      assertNever(stockStatus);
  }
};

export const StoreStockHeader = styled.span`
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  color: ${color.webBlack.hex};
  ${typographyToCss(text.header)};

  svg {
    padding-right: 7px;
  }
`;

export const StoreStockChooseProductSizeMessage = styled.p`
  margin: 0 0 ${spaces.regular};
  font-size: 0.9375rem;
  font-family: var(--font-family-regular);
  line-height: 1.2;
`;

export const StoreStockListStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  ${typographyToCss(text.stockList)};
`;

export const StoreStockListItem = styled.li`
  ${typographyToCss(text.stockList)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spaces.mini} 0;
  border-bottom: 1px solid ${mainColors.xxlMediumGrey};
  color: ${mainColors.xxlWebBlack};
`;

export const StoreStockListSublistHeader = styled.div`
  margin: ${spaces.smallRegular} 0;
  ${typographyToCss(typography.mediumBold)};
  color: ${color.webBlack.hex};
`;

export const StoreStockStatus = styled.span`
  white-space: nowrap;
  text-transform: lowercase;
`;

type StoreStockIndicatorProps = {
  stockStatus: StockStatusType | null;
};

export const StoreStockStatusIndicator = styled.span<StoreStockIndicatorProps>`
  display: inline-flex;
  align-items: center;
  min-width: 10px;
  width: 10px;
  height: 10px;
  margin: 0;
  margin-right: ${spaces.mini};
  background: ${({ stockStatus }): string =>
    getStockIndicatorColor(stockStatus)};
  border-radius: 50%;
`;

export const StoreStockSeeAllStoresButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${spaces.micro};
  padding: 0;
  color: ${mainColors.xxlWebBlack};
  ${typographyToCss(text.allStoresButton)};
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const StoreStockClickableListItem = styled.li<StoreStockClickableListItemProps>`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: ${spaces.large};
  border-bottom: 1px solid var(--color-xxl-medium-gray);
  padding: ${spaces.smallRegular} 0;
  color: ${mainColors.xxlDarkGrey};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${mainColors.xxlWebBlack};
      & > span {
        font-weight: 700;
      }
    `}

  ${interactionFeedback("font-weight: 700;")}

  &:first-of-type {
    padding-top: 0;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      color: ${mainColors.xxlGrey};
      span:first-of-type {
        color: ${mainColors.xxlGrey};
      }

      &:hover {
        font-weight: 100;
      }
    `}
`;

export const StoreStockSelectedDetails = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};
  h3 {
    margin: 0;
    font-size: 0.9rem;
  }
  p {
    margin: 0;
    font-family: var(--font-family-regular);
    line-height: 19px;
  }
`;

export const StoreStockSelectedDetailsMapLinkStyled = styled.a`
  line-height: 19px;
  color: #000;
  align-items: center;
  display: flex;
  text-decoration: underline;
  underline-offset: 5px;

  p {
    font-family: var(--font-family-medium);
  }
`;

export const StoreStockSelectedDetailsMapImageStyled = styled.img`
  max-width: 100%;
`;

type MessagesProps = {
  isVisible: boolean;
};
export const Messages = styled.div<MessagesProps>`
  width: 100%;
  height: ${({ isVisible }) => (isVisible ? "auto" : "0")};
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spaces.smallRegular} 0;
  gap: ${spaces.miniMicro};
`;
